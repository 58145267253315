import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Home } from '@mui/icons-material'
import { Button, Container, Typography } from '@mui/material'
import { Field, Form, Formik, FormikProps } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, Dialog, ErrorDisplay, Image, SeoHeaders } from 'shared/components'
import { setFormError } from 'shared/services'

import NavBar from '../../components/nav_bar'
import GuitaLogo from '../../images/guita-logo.svg'
import { ADMIN_PASSWORD_RESET_MUTATION } from '../../queries/auth'
import { translateGuitaError } from '../../services/error_messages'

import type { AdminPasswordResetData, AdminPasswordResetVars } from '../../queries/auth'

type FormValues = AdminPasswordResetVars

const initialValues: FormValues = {
  domain: '',
  email: '',
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  domain: Yup.string()
    .required('Este campo es obligatorio'),
  email: Yup.string()
    .email('Tu email es inválido')
    .max(64, 'Tu email es muy largo')
    .required('Este campo es obligatorio'),
})

const PasswordResetForm = ({
  isSubmitting,
  isValid,
  status,
  submitted,
  submitForm,
}: FormikProps<FormValues> & { submitted: boolean }) => (
  <Form
    onSubmit={(event) => {
      event?.preventDefault()
      submitForm()
    }}
    style={{ width: '100%' }}
  >
    <Field
      required
      name='domain'
      type='text'
      label='Dominio'
      component={TextField}
      margin='normal'
      inputProps={{
        autoComplete: 'organization',
      }}
      fullWidth
    />
    <Field
      required
      name='email'
      type='email'
      label='Email'
      placeholder='satoshin@gmx.com'
      component={TextField}
      margin='normal'
      fullWidth
    />
    <ErrorDisplay
      errorMsg={status?.errorMsg}
      mt={2}
    />
    <Button
      disabled={submitted || isSubmitting || !isValid}
      onClick={submitForm}
      variant='contained'
      size='large'
      sx={{ mt: 2 }}
      fullWidth
    >
      Enviar
    </Button>
  </Form>
)

const PasswordReset = () => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const [adminPasswordReset] =
    useMutation<AdminPasswordResetData, AdminPasswordResetVars>(ADMIN_PASSWORD_RESET_MUTATION, {
      errorPolicy: 'all',
    })

  const handleSubmit = async (values: FormValues) => {
    const response = await adminPasswordReset({ variables: values })
    const ok = response.data?.adminSendPasswordResetWithToken

    if (ok) {
      setSubmitted(true)
      openDialog()
    } else {
      setFormError(formRef, translateGuitaError(response))
    }
  }

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <SeoHeaders title='Restablecer Contraseña' />
      <NavBar />
      <Container
        maxWidth='xs'
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
          gap: 2,
          px: 2,
          pt: 2,
          pb: 4,
        })}
      >
        <Image
          src={GuitaLogo}
          alt='Logo Guita'
          duration={300}
        />
        <Typography textAlign='center'>
          Ingresa el email asociado a tu cuenta para iniciar el proceso:
        </Typography>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <PasswordResetForm
              submitted={submitted}
              {...props}
            />
          )}
        </Formik>
      </Container>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Revisa tu bandeja de entrada'
        contentText={'Si tu email está registrado, recibirás un correo con ' +
                     'instrucciones para restablecer tu contraseña.'}
      >
        <ButtonsContainer mt={2}>
          <ButtonContainer xs={12}>
            <Button
              fullWidth
              href='/auth/login/'
              variant='contained'
              endIcon={<Home />}
              size='large'
            >
              Volver al inicio
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  )
}

export default PasswordReset
